// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery3
//= require popper
//= require global
import "cocoon-js";

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import * as bootstrap from 'bootstrap';
import "trix";
import "@rails/actiontext";
import "packs/global";
import "packs/pages";
import "packs/online_shop";
import "plugins/jquery.numpad";
import "plugins/jquery.autocomplete";
import "plugins/toastr.min";
import "plugins/jquery.mask.min";
// import "plugins/jquery.freezetable";

Rails.start();
import Turbolinks from "turbolinks";
Turbolinks.start();
ActiveStorage.start();
